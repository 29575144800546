<template>
    <section class="from-a-to-zumba">
        <div class="header-section">
            <h3 class="from-zumba-title z-h3">
                {{ t.title }}
            </h3>

            <p class="from-zumba-subtitle z-b3 f-inter">
                {{ t.subtitle }}
            </p>
        </div>

        <div class="fitness-types-section">
            <!-- Fitness Types Mobile -->
            <div class="fitness-types-container container-mobile">
                <CarouselShared
                    slider-selector=".fitness-type-slider"
                    slide-selector=".fitness-type-slide"
                    membership-type="zumba_app"
                    :include-dot-buttons="true"
                    :include-direction-buttons="false"
                    :buttons-compacted="true"
                    :interval="7000"
                    :amount-of-slides="carrouselAmountOfSlides"
                >
                    <div class="fitness-type-slider clearfix">
                        <template
                            v-for="(fitnessTypesChunk, indexChunk) in fitnessTypesChunked"
                            :key="`fitness-type-chunk-${indexChunk}`"
                        >
                            <div class="fitness-type-slide">
                                <FitnessTypeCard
                                    v-for="(data, index) in fitnessTypesChunk"
                                    :key="`fitness-type-mobile-${index}`"
                                    :fitness-type="data"
                                />
                            </div>
                        </template>
                    </div>
                </CarouselShared>
            </div>

            <!-- Fitness Types Desktop -->
            <div class="fitness-types-container container-desktop">
                <FitnessTypeCard
                    v-for="(data, index) in fitnessTypes"
                    :key="`fitness-type-desktop-${index}`"
                    :fitness-type="data"
                />
            </div>
        </div>

        <div class="footer-section">
            <ZButton
                variety="optimus"
                :href="ctaHref"
                compact="never"
                wide="never"
                class="try-it-cta"
            >
                {{ t.tryItCta }}
            </ZButton>

            <template v-if="hasTrialDays">
                <p class="z-b5 f-inter trial-disclaimer">
                    {{ t.trialDisclaimer }}
                </p>
            </template>
        </div>
    </section>
</template>

<script lang="ts" setup>
import {PropType, computed} from "vue";
import ZButton from "@components/Core/ZButton.vue";
import CarouselShared from "@components/Core/CarouselShared.vue";
import FitnessTypeCard from "@components/AppSubscriptionLanding/FitnessTypeCard.vue";
import chunk from "lodash-es/chunk"

export interface FitnessType {
    backgroundUrl: string,
    alt: string,
    name: string,
}

const props = defineProps({
    t: {
        type: Object,
        default: () => ({
            title: 'From A to Zumba®',
            subtitle: 'Whether you want to cumbia and chill or HIIT your strength-training goals, access our on-going monthly library of workouts suited for all fitness levels',
            tryItCta: 'Try it Free',
            trialDisclaimer: 'Free trial available for a limited-time only, risk-free!'
        })
    },
    fitnessTypes: {
        type: Array as PropType<FitnessType[]>,
        default: () => []
    },
    ctaHref: {
        type: String,
        required: true
    },
    hasTrialDays: {
        type: Boolean,
        default: () => true
    }
})

const carrouselMaxItems = 4

const carrouselAmountOfSlides = computed(() => Math.ceil(props.fitnessTypes.length / carrouselMaxItems))

const fitnessTypesChunked = chunk(props.fitnessTypes, carrouselMaxItems)

</script>

<style lang="scss">
.from-a-to-zumba {
    padding: 2.5rem 1.5rem;

    @media only screen and (min-width: 64em) {
        padding: 5rem 7rem;
    }

    .header-section {
        text-align: center;
        margin-bottom: 2rem;

        @media only screen and (min-width: 64em) {
            max-width: 49.75rem;
            margin-left: auto;
            margin-right: auto;
        }

        .from-zumba-title {
            text-transform: uppercase;
            margin-bottom: 0.75rem;
            color: var(--zumba-gray-800) !important;

            @media only screen and (min-width: 64em) {
                font-size: 3.125rem;
                line-height: 3.375rem;
            }
        }

        .from-zumba-subtitle {
            margin: 0 1.5rem;
            color: var(--zumba-gray-800) !important;

            @media only screen and (min-width: 64em) {
                font-size: 1.125rem;
                line-height: 1.625rem;
                margin: 0 3.375rem;
            }
        }
    }

    .fitness-types-section {
        margin-bottom: 2rem;

        .fitness-types-container {
            max-width: 23.875rem;
            margin: auto;

            @media only screen and (min-width: 48em) {
                display: flex;
                justify-content: center;
                align-content: center;
                gap: 2rem;
                flex-wrap: wrap;
                max-width: 76rem;
            }

            &.container-mobile {
                @media only screen and (min-width: 48em) {
                    display: none;
                }
            }

            &.container-desktop {
                display: none;

                @media only screen and (min-width: 48em) {
                    display: flex;
                }
            }
        }

        .fitness-type-slide {
            display: flex;
            gap: 1.5rem;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            margin-bottom: 2rem;
        }
    }

    .footer-section {
        text-align: center;
        padding: 0 2.938rem;

        .try-it-cta {
            display: inline-block;
        }

        .trial-disclaimer {
            margin-top: 1rem;
        }

        p {
            margin-bottom: 0;
            color: var(--zumba-gray-800) !important;
        }
    }
}
</style>
