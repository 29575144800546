<template>
    <section class="subscription-section-common">
        <div class="ayp-container">
            <div class="ayp-content">
                <p class="ayp-section-name z-b3 f-inter">
                    Zumba® Virtual+
                </p>
                <div class="ayp-header-section">
                    <h3 class="mobile-only z-h3 ayp-header">
                        {{ t.header1 }}
                    </h3>
                    <h1 class="bigger-than-mobile z-h1 ayp-header">
                        {{ t.header1 }}
                    </h1>
                    <h3 class="mobile-only z-h3 ayp-header">
                        {{ t.header2 }}
                    </h3>
                    <h1 class="bigger-than-mobile z-h1 ayp-header">
                        {{ t.header2 }}
                    </h1>
                </div>
                <!-- eslint-disable vue/no-v-html-->
                <div
                    class="ayp-description"
                    v-html="t.subheader"
                />
                <z-button
                    class="z-b5 ayp-download-button"
                    :label="t.download"
                    :disabled="false"
                    :href="downloadUrl"
                    :wide="'never'"
                >
                    {{ props.t.downloadButton }}
                </z-button>
            </div>
            <div class="ayp-image">
                <img
                    src="https://d29za44huniau5.cloudfront.net/uploads/2024/3/at_your_pace_graphics.svg"
                    alt="at_your_pace_graphics"
                >
            </div>
        </div>
    </section>
</template>
<script lang="ts">
const localT = {
    header1: 'At your pace.',
    header2: 'At your place.',
    subheader: 'Go from beginner to booty-shaker with workouts designed to help you get the most out of your fitness routine.',
    downloadButton: 'Download',
}
</script>
<script setup lang="ts">
import { PropType } from 'vue'
import ZButton from '@components/Core/ZButton.vue'

const props = defineProps({
    t: {
        type: Object as PropType<Record<any, string>>,
        default: () => {localT},
    },
    downloadUrl: {
        type: String,
        required: true,
    },
})

</script>

<style scoped>
.subscription-section-common {
    display: flex;
    justify-content: center;
}
.bigger-than-mobile {
    display: none;
}
.mobile-only {
    display: block;
}
.ayp-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2.5rem 1.5rem 0rem 1.5rem;
    text-align: center;
    margin-bottom: -0.3125rem;
    width: 100.5rem;
}
.ayp-section-name {
    margin-bottom: 0.75rem;
}
.ayp-header-section {
    margin-bottom: 0.75rem;
}
.ayp-header {
    text-transform: uppercase;
}
.ayp-description p{
    margin-bottom: 2rem;
}
.ayp-download-button {
    margin-bottom: 2.25rem;
}
.ayp-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.ayp-image {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
}
.ayp-image svg {
    max-width: 100%;
    height: auto;
}
@media only screen and (min-width:64em) {
    .bigger-than-mobile {
        display: block;
    }
    .mobile-only {
        display: none;
    }
    .ayp-container {
        flex-direction: row;
        justify-content: space-between;
        padding: 5.625rem 7rem 0rem 7rem;
    }
    .ayp-content {
        display: block;
        text-align: left;
    }
    .ayp-description {
        max-width: 25.6875rem;
    }
    .ayp-image svg {
        max-width: 100%;
        height: auto;
    }
}
@media only screen and (min-width:100.5em) {
    .ayp-container {
        padding: 5.625rem 7rem 0rem 11.4rem;
    }
}
</style>
