<template>
    <section class="meet-the-instructors">
        <div class="instructors-section">
            <div class="instructors-header-section">
                <!-- eslint-disable vue/no-v-html-->
                <div
                    class="instructors-title"
                    v-html="t.title"
                />

                <p class="instructors-subtitle z-b3 f-inter">
                    {{ t.subtitle }}
                </p>
            </div>

            <div
                ref="flexViewportRef"
                class="instructors-container"
            >
                <InstructorCard
                    v-for="(data, index) in instructors"
                    :id="`instructor-card-${index}`"
                    :key="`instructor-slide-${index}`"
                    :instructor="data"
                />
            </div>

            <ol class="instructor-slider-controls">
                <li
                    v-for="(data, index) in instructors"
                    :key="`instructor-button-${index}`"
                >
                    <a
                        :class="{
                            'active': instructorSelected === index
                        }"
                        @click="handleClick(index)"
                    >
                        {{ index + 1 }}
                    </a>
                </li>
            </ol>
        </div>

        <div class="footer-section">
            <ZButton
                variety="robin"
                :href="ctaHref"
                compact="never"
                wide="never"
                class="try-it-cta"
            >
                {{ t.tryItCta }}
            </ZButton>
        </div>
    </section>
</template>

<script lang="ts" setup>
import {onMounted, PropType, ref} from "vue";
import ZButton from "@components/Core/ZButton.vue";
import InstructorCard from "@components/AppSubscriptionLanding/InstructorCard.vue";

export interface Instructor {
    imageUrl: string,
    name: string,
    description: string,
}

let timer;

const props = defineProps({
    t: {
        type: Object,
        default: () => ({
            title: '<h1 class="z-h3">Meet the <strong>Instructors</strong></h1>',
            subtitle: 'From world-renowned dance pros to champion athletes and fitness experts, our team of instructors will inspire you every step of the way.',
            tryItCta: 'Try it Free',
        })
    },
    instructors: {
        type: Array as PropType<Instructor[]>,
        default: () => []
    },
    ctaHref: {
        type: String,
        required: true
    },
    interval: {
        type: Number,
        default: () => 5000
    },
})

const flexViewportRef = ref<HTMLElement>()
const instructorSelected = ref(0)

onMounted(() => {
    timer = setInterval(() => goToNextSlide(), props.interval);
})

const goToNextSlide = () => {
    if (instructorSelected.value === props.instructors.length - 1) {
        goToSlide(0);
        return;
    }

    goToSlide(instructorSelected.value + 1);
}

const handleClick = (index: number) => {
    goToSlide(index);
    resetTimer();
}

const resetTimer = () => {
    clearInterval(timer);
    timer = setInterval(() => goToNextSlide(), props.interval);
}

const goToSlide = (index: number) => {
    instructorSelected.value = index;

    const card: HTMLElement = flexViewportRef.value?.querySelector(`#instructor-card-${index}`)

    flexViewportRef.value?.scrollTo({
        left: card?.offsetLeft! +
            card?.offsetWidth! / 2 - flexViewportRef.value?.offsetWidth! / 2,
        behavior: 'smooth',
    });
}
</script>

<style lang="scss">
.meet-the-instructors {
    background-color: var(--zumba-dark-green);
    padding: 2rem 1.5rem;
    border-radius: 2.5rem 2.5rem 0 0;

    @media only screen and (min-width: 64em) {
        padding: 6.25rem 7rem;
        border-radius: 5rem 5rem 0 0;
    }

    .instructors-section {
        margin: auto;

        @media only screen and (min-width: 48em) {
            max-width: 76rem;
        }

        @media only screen and (min-width: 64em) {
            margin-bottom: 2rem;
        }

        .instructors-header-section {
            margin-bottom: 2rem;

            @media only screen and (min-width: 64em) {
                max-width: 42.625rem;
            }

            .instructors-title {
                margin-bottom: 0.75rem;

                > * {
                    color: var(--zumba-white) !important;

                    @media only screen and (min-width: 64em) {
                        font-size: 3.125rem;
                        line-height: 3.375rem;
                    }
                }

                strong {
                    color: var(--zumba-neon-green);
                }
            }

            .instructors-subtitle {
                color: var(--zumba-white) !important;

                @media only screen and (min-width: 64em) {
                    font-size: 1.125rem;
                    line-height: 1.625rem;
                }
            }
        }

        .instructors-container {
            display: flex;
            gap: 2rem;
            align-items: stretch;
            align-content: stretch;
            flex-wrap: nowrap;
            overflow: hidden;
            overflow-x: auto;
            scroll-snap-type: x mandatory;
            -webkit-overflow-scrolling: touch;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                width: 0;
                background: transparent;
            }

            @media only screen and (max-width: 64em) {
                margin: 0 -2rem;
                padding: 0 2rem;
            }

            @media only screen and (min-width: 90em) {
                flex-wrap: wrap;
                justify-content: space-evenly;
            }

            > * {
                flex: 0 0 auto;
            }
        }

        .instructor-slider-controls {
            display: none;
            margin: 2rem 0 0;
            text-align: center;
            line-height: 0;

            @media only screen and (max-width: 64em) {
                display: block;
            }

            li {
                display: inline-block;
                margin: 0 .375em;
                zoom: 1;

                a {
                    display: block;
                    width: .875rem;
                    height: .875rem;
                    background: var(--zumba-gray-200);
                    opacity: 0.2;
                    cursor: pointer;
                    text-indent: -9999px;
                    border-radius: 50%;

                    &.active {
                        background: var(--zumba-neon-green);
                        opacity: 1;
                    }
                }
            }
        }
    }

    .footer-section {
        display: none;
        text-align: center;

        @media only screen and (min-width: 64em) {
            display: block;
        }

        .try-it-cta {
            display: inline-block;
        }
    }
}
</style>
