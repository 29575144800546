<template>
    <section
        class="hero"
        :style="{
            'background-image': `url(${fallbackImageHref})`,
            'background-size': 'cover',
        }"
    >
        <video
            class="hero-video"
            autoplay
            loop
            muted
            playsinline
        >
            <source
                :src="videoSrc"
                type="video/mp4"
            >
            Your browser does not support the video tag.
        </video>

        <div class="overlay" />

        <div class="hero-content">
            <div class="hero-badge">
                <AppIcon />
            </div>

            <h2
                :class="{'hero-header': true, 'z-h1' : isDesktop(), 'z-h2' : isMobile()}"
            >
                {{ t.header }}
            </h2>

            <h6 class="hero-subheader z-h6">
                {{ t.subheader }}
            </h6>

            <ZButton
                variety="robin"
                :href="ctaHref"
                compact="never"
                wide="never"
                class="hero-cta"
            >
                {{ t.tryItCta }}
            </ZButton>
            <div
                v-if="showFsaInformation"
                class="z-b4 inter hsafsa"
            >
                <strong>HSA/FSA Eligible. Save an average of 30%</strong>
                <a
                    href="#"
                    class="link"
                    data-mixpanel-command-name="TrackTruemedLearnMoreModalClicked"
                    data-mixpanel-properties="{&quot;buttonType&quot;: &quot;Learn More Modal&quot;}"
                    data-mixpanel-page-name="subscription"
                    @click="showFsaHsaModal = true"
                >
                    <strong>Learn more</strong>
                </a>
            </div>
        </div>
    </section>
    <fsa-hsa-information
        v-if="showFsaInformation"
        :t="t"
        :show="showFsaHsaModal"
        true-med-link="https://app.truemed.com/survey/422cb959-6b20-4596-a3ac-94b4924f481f"
        faq-link="/faq"
        @closed="showFsaHsaModal = false"
    />
</template>

<script lang="ts" setup>
import { defineProps, ref } from "vue";
import AppIcon from "@images/AppSubscriptionLanding/AppIcon.svg"
import ZButton from "@components/Core/ZButton.vue";
import FsaHsaInformation from "./FsaHsaInformation.vue";
import {isMobileReactive, isMobile, isDesktop} from "@ts/Util/responsiveness";
import {computed} from "vue";

const props = defineProps({
    t: {
        type: Object,
        default: () => ({
            header: 'Unlock the Fun',
            subheader: 'Subscribe to Zumba Virtual+ and get unlimited access to the best on-demand Zumba classes.',
            tryItCta: 'Try it Free',
        })
    },
    videoHref: {
        type: String,
        required: true
    },
    videoMobileHref: {
        type: String,
        required: false,
        default: ''
    },
    fallbackImageHref: {
        type: String,
        required: true
    },
    fallbackImageHrefMobile: {
        type: String,
        required: true
    },
    ctaHref: {
        type: String,
        required: true
    },
    showFsaInformation: {
        type: Boolean,
        required: true
    }
})

const videoSrc = computed(() => {
    const isMobile = isMobileReactive()

    return isMobile.value ? props.videoMobileHref : props.videoHref
});

const fallbackImageHref = computed(() => {
    const isMobile = isMobileReactive()

    return isMobile.value ? props.fallbackImageHrefMobile : props.fallbackImageHref
});

const showFsaHsaModal = ref(false)
</script>

<style lang="scss">
.hero {
    position: relative;
    overflow: hidden;
    height: calc(100vh - 4.813rem);
    min-height: 24rem;
    background-color: #fff;
    background-repeat: no-repeat;
    background-size: 100% 100%;

    @media only screen and (min-width: 48em) {
        height: calc(100vh - 5.125rem);
    }

    @media only screen and (min-width: 64em) {
        height: calc(100vh - 7.313rem);
    }

    .hero-video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .hero-content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        color: #fff;
        width: 80%;
        margin: 0 auto;

        @media only screen and (min-width: 48em) {
            width: 75%;
        }

        @media only screen and (min-width: 64em) {
            width: 50%;
        }

        .hero-header {
            color: var(--zumba-white);
            margin-bottom: 1rem;
            padding: 0 1.25rem;

            @media only screen and (min-width: 64em) {
                padding: 0;
                font-family: "Zandes-Extra-Bold", Helvetica, Arial, sans-serif;
            }
        }

        .hero-subheader {
            color: var(--zumba-white);
            margin-bottom: 1.5rem;

            @media only screen and (min-width: 64em) {
                font-size: 1.25rem;
                line-height: 1.625rem;
                padding: 0 6.434rem;
            }
        }

        .hero-cta {
            display: inline-block;
            margin-bottom: 1.5rem;
        }

        .hsafsa {
            display: flex;
            flex-direction: column;
            align-items: center;

            a {
                color: var(--zumba-white);
                text-decoration: underline;
            }
        }
    }

    .hero-badge {
        margin-bottom: 2.344rem;
    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.4);
    }
}
</style>
