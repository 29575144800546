<template>
    <div class="ryr-section">
        <div class="ryr-wording">
            <div class="ryr-content">
                <div class="ryr-header-section">
                    <h3 class="mobile-only z-h3 ryr-header">
                        {{ t.header1 }}
                    </h3>
                    <h1 class="bigger-than-mobile z-h1 ryr-header">
                        {{ t.header1 }}
                    </h1>
                </div>
                <!-- eslint-disable vue/no-v-html-->
                <div
                    class="ryr-description"
                    v-html="t.subheader"
                />
                <z-button
                    class="z-b5 ryr-download-button"
                    :label="t.tryItButton"
                    :disabled="false"
                    :href="tryItUrl"
                    :wide="'never'"
                >
                    {{ props.t.tryItButton }}
                </z-button>
            </div>
            <div class="ryr-image bigger-than-mobile">
                <img
                    src="https://d29za44huniau5.cloudfront.net/uploads/2024/3/Image-Module2.svg"
                    alt="at_your_pace_graphics"
                >
            </div>
        </div>
        <div class="ryr-features">
            <div class="mobile-only">
                <CarouselShared
                    slide-selector=".ryr-slider-item"
                    slider-selector=".ryr-slider"
                    :include-dot-buttons="true"
                    :include-direction-buttons="false"
                    :interval="7000"
                    :amount-of-slides="features.length"
                    :item-width="382"
                >
                    <div
                        class="ryr-slider"
                    >
                        <div
                            v-for="feature in features"
                            :key="feature.title"
                            class="ryr-slider-item"
                        >
                            <img
                                class="ryr-feature-image"
                                :src="feature.iconUrl"
                                :alt="feature.title"
                            >
                            <h6 class="z-h6 f-inter ryr-section-name">
                                {{ feature.title }}
                            </h6>
                            <p class="z-b3 f-inter">
                                {{ feature.description }}
                            </p>
                        </div>
                    </div>
                </CarouselShared>
            </div>
            <div class="bigger-than-mobile">
                <div
                    class="ryr-slider"
                >
                    <div
                        v-for="feature in features"
                        :key="feature.title"
                        class="ryr-slider-item"
                    >
                        <img
                            class="ryr-feature-image"
                            :src="feature.iconUrl"
                            :alt="feature.title"
                        >
                        <h6 class="z-h6 f-inter ryr-section-name">
                            {{ feature.title }}
                        </h6>
                        <p class="z-b3 f-inter">
                            {{ feature.description }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
const localT = {
    header1: 'Remix Your Routine',
    subheader: 'From beginners dance tutorials to high-intensity interval training, there\'s a workout for every body.',
    tryItButton: 'Try it free',
}
type Feature = {
    iconUrl: string,
    title: string,
    description: string,
}
</script>
<script setup lang="ts">
import { PropType } from 'vue'
import ZButton from '@components/Core/ZButton.vue'
import CarouselShared from "@components/Core/CarouselShared.vue";

const props = defineProps({
    t: {
        type: Object as PropType<Record<any, string>>,
        default: () => {localT},
    },
    features: {
        type: Array as PropType<Feature[]>,
        default: () => [],
    },
    tryItUrl: {
        type: String,
        required: true,
    },
})

</script>

<style scoped>
.bigger-than-mobile {
    display: none;
}
.mobile-only {
    display: block;
}
.ryr-section {
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.ryr-wording {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2.5rem 1.5rem 0rem 1.5rem;
    text-align: center;
    margin-bottom: -0.3125rem;
}
.ryr-section-name {
    margin-bottom: 0.75rem;
}
.ryr-header-section {
    margin-bottom: 0.75rem;
}
.ryr-header {
    text-transform: uppercase;
}
.ryr-description p{
    margin-bottom: 2rem;
}
.ryr-download-button {
    margin-bottom: 2rem;
}
.ryr-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.ryr-image {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
}
.ryr-image svg {
    max-width: 100%;
    height: auto;
}
.ryr-slider {
    display: inline-block;
    width: 100%;
    padding: 1rem;
}
.ryr-slider-item {
    display: block;
    padding: 2rem;
    background-color: var(--zumba-white);
    border-radius: 1.25rem;
}
.ryr-slider-item img {
    margin-bottom: 1.25rem;
    width: 1.880rem;
    flex-shrink: 0;
}
@media only screen and (min-width:64em) {
    .ryr-section {
        flex-direction: column;
        align-items: center;
    }
    .ryr-wording {
        flex-direction: row;
        justify-content: space-between;
        padding: 5.625rem 7rem 0rem 7rem;
        max-width: 100.5rem;
    }
    .ryr-content {
        display: block;
        text-align: left;
    }
    .ryr-description {
        max-width: 25.6875rem;
    }
    .ryr-image svg {
        max-width: 100%;
        height: auto;
    }
    .ryr-features {
        display: flex;
        justify-content: center;
        padding: 0 7rem;
        margin-top: -4.375rem;
        margin-bottom: 6.25rem;
    }
    .ryr-slider {
        display: flex;
        justify-content: center;
        max-width: 100.5rem;
        flex-wrap: wrap;
        gap: 2rem;
    }
    .ryr-slider-item {
        max-width: 23.875rem;
        max-height: 16rem;
    }
    .ryr-slider-item img {
        width: 5rem;
    }
    .bigger-than-mobile {
        display: block;
    }
    .mobile-only {
        display: none;
    }
}
@media only screen and (min-width:100.5em) {
    .ryr-features {
        margin-top: -8.75rem;
    }
    .ryr-wording {
        padding: 5.625rem 7rem 0rem 12.5rem;
    }
}
</style>
