<template>
    <section class="easy-as-one-two-three">
        <div class="container">
            <div class="devices-section">
                <img
                    :src="devicesImageHref"
                    alt="Devices"
                >
            </div>
            <div class="brand-section">
                <!-- eslint-disable vue/no-v-html-->
                <div
                    class="easy-title"
                    v-html="t.title"
                />

                <p class="easy-subtitle z-b3 f-inter">
                    {{ t.subtitle }}
                </p>

                <div
                    v-if="tvProviders.length"
                    class="tv-providers-section"
                >
                    <template
                        v-for="{ url, alt } in tvProviders"
                        :key="url"
                    >
                        <img
                            :src="url"
                            :alt="alt || '#'"
                        >
                    </template>
                </div>

                <ZButton
                    variety="robin"
                    :href="ctaHref"
                    compact="never"
                    wide="never"
                    class="try-it-cta"
                >
                    {{ t.tryItCta }}
                </ZButton>
            </div>
        </div>
    </section>
</template>

<script lang="ts" setup>
import {PropType} from "vue";
import ZButton from "@components/Core/ZButton.vue";

export interface TvProvider {
    url: string,
    alt: string|null,
}

const props = defineProps({
    t: {
        type: Object,
        default: () => ({
            title: 'Easy as 1-2-3!',
            subtitle: 'Stream classes to your SmartTV or take the party on the go with your phone, laptop, or tablet - all included in your membership.',
            tryItCta: 'Try it Free',
        })
    },
    devicesImageHref: {
        type: String,
        required: true,
    },
    tvProviders: {
        type: Array as PropType<TvProvider[]>,
        default: () => []
    },
    ctaHref: {
        type: String,
        required: true
    },
})
</script>

<style lang="scss">
.easy-as-one-two-three {
    background-color: var(--zumba-dark-purple);
    background-image: url('@images/AppSubscriptionLanding/EasyAsOneTwoThree/background-mobile.svg?external');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    min-height: 40.563rem;
    position: relative;
    overflow: hidden;

    @media only screen and (min-width: 64em) {
        background-image: url('@images/AppSubscriptionLanding/EasyAsOneTwoThree/background.svg?external');
        min-height: 43rem;
    }

    .container {
        @media only screen and (min-width: 64em) {
            display: flex;
            gap: 4.313rem;
            padding: 7.75rem 4.438rem 0;
            justify-content: center;
        }
    }

    .devices-section {
        padding: 2.5rem 2rem 0;
        margin-bottom: 1.438rem;
        text-align: center;

        @media only screen and (min-width: 64em) {
            padding: 0;
            margin-bottom: 0;
        }

        img {
            width: 100%;
            max-height: 29.556rem;
            max-width: 41.866rem;
        }
    }

    .brand-section {
        padding: 0 1.5rem;
        color: var(--zumba-white);
        text-align: center;

        @media only screen and (min-width: 64em) {
            padding: 0;
            text-align: left;
            max-width: 32.375rem;
            margin-top: 0.844rem;
        }

        .easy-title {
            margin-bottom: 1rem;

            @media only screen and (min-width: 64em) {
                text-align: left;
            }

            > * {
                color: var(--zumba-white);

                @media only screen and (min-width: 64em) {
                    font-size: 3.125rem;
                    line-height: 3.375rem;
                }
            }

            strong {
                color: var(--zumba-neon-green) !important;
            }
        }

        .easy-subtitle {
            padding: 0 1.25rem;
            margin-bottom: 1.5rem;

            @media only screen and (min-width: 64em) {
                padding: 0 4.813rem 0 0;
                margin-bottom: 4.563rem;
                font-size: 1.125rem;
                line-height: 1.625rem;
            }
        }

        .tv-providers-section {
            text-align: left !important;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            gap: 1.375rem;
            margin-bottom: 1.5rem;

            @media only screen and (min-width: 64em) {
                margin-bottom: 4.563rem;
            }

            img {
                max-width: 100%;
                max-height: 100%;
                display: inline-block;
            }
        }

        .try-it-cta {
            display: inline-block;
            margin-bottom: 2.5rem;

            @media only screen and (min-width: 64em) {
                margin-bottom: 0;
            }
        }
    }
}
</style>
