#layout-page-content {
	margin: 0 -1em;
}
#subscription-container {
	background-color: var(--zumba-gray-100);
}

#qr-code-rendered {
	canvas {
		width: 11.25rem;
		height: 11.25rem;
		padding: 1.17rem;
		border-radius: 1.5rem;
		background-color: var(--zumba-white);
	}
}